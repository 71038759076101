
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';

interface FallbackScreenProps {
  message?: string;
  onRetry?: () => void;
  showHomeButton?: boolean;
  title?: string;
}

const FallbackScreen: React.FC<FallbackScreenProps> = ({ 
  message = "Une erreur est survenue lors du chargement de l'application.",
  onRetry = () => window.location.reload(),
  showHomeButton = true,
  title = "GuTeco"
}) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/', { replace: true });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
      <div className="max-w-md w-full p-6 sm:p-8 bg-white rounded-lg shadow-lg text-center">
        <div className="w-20 h-20 mx-auto mb-5 rounded-full bg-amber-100 flex items-center justify-center">
          <AlertTriangle className="h-10 w-10 text-amber-600" />
        </div>
        <h1 className="text-2xl font-bold text-gray-800 mb-3">{title}</h1>
        <p className="text-gray-600 mb-6">{message}</p>
        <div className="space-y-3">
          <button 
            onClick={onRetry}
            className="w-full px-6 py-3 bg-amber-500 text-black font-medium rounded-lg hover:bg-amber-600 transition-colors"
          >
            Rafraîchir la page
          </button>
          
          {showHomeButton && (
            <button 
              onClick={handleHomeClick}
              className="w-full px-6 py-3 bg-gray-200 text-gray-800 font-medium rounded-lg hover:bg-gray-300 transition-colors"
            >
              Retourner à l'accueil
            </button>
          )}
        </div>
        <p className="mt-6 text-sm text-gray-500">
          Si le problème persiste, veuillez nous contacter à gtc.vcarbs@gmail.com
        </p>
      </div>
    </div>
  );
};

export default FallbackScreen;
