
import { toast as sonnerToast } from "sonner"

type ToastProps = {
  title?: string
  description?: string
  variant?: "default" | "destructive" | "success" | "warning" | "info"
}

export function useToast() {
  const toast = ({ title, description, variant = "default" }: ToastProps) => {
    switch (variant) {
      case "success":
        sonnerToast.success(title || "Succès", {
          description,
        })
        break
      case "destructive":
        sonnerToast.error(title || "Erreur", {
          description,
        })
        break
      case "warning":
        sonnerToast.warning(title || "Avertissement", {
          description,
        })
        break
      case "info":
        sonnerToast.info(title || "Information", {
          description,
        })
        break
      default:
        sonnerToast(title || "", {
          description,
        })
    }
  }

  return { toast }
}

// Main toast function
export const toast = ({ title, description, variant = "default" }: ToastProps) => {
  switch (variant) {
    case "success":
      sonnerToast.success(title || "Succès", {
        description,
      })
      break
    case "destructive":
      sonnerToast.error(title || "Erreur", {
        description,
      })
      break
    case "warning":
      sonnerToast.warning(title || "Avertissement", {
        description,
      })
      break
    case "info":
      sonnerToast.info(title || "Information", {
        description,
      })
      break
    default:
      sonnerToast(title || "", {
        description,
      })
  }
}

// Helper functions for more convenient usage
export const toastSuccess = (title: string, description?: string) => {
  sonnerToast.success(title, { description });
}

export const toastError = (title: string, description?: string) => {
  sonnerToast.error(title, { description });
}

export const toastWarning = (title: string, description?: string) => {
  sonnerToast.warning(title, { description });
}

export const toastInfo = (title: string, description?: string) => {
  sonnerToast.info(title, { description });
}

export const toastDefault = (title: string, description?: string) => {
  sonnerToast(title, { description });
}
