
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Layout } from '@/components/Layout';
import CarbonNFT from '@/components/CarbonNFT';
import { Badge } from '@/components/ui/badge';
import { Check, Shield, ArrowLeft } from 'lucide-react';
import { toastSuccess, toastInfo } from '@/hooks/use-toast';
import { useIsMobile } from '@/hooks/use-mobile';
import { Button } from '@/components/ui/button';

const VerifyNFT = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(true);
  
  const credits = searchParams.get('credits') ? parseInt(searchParams.get('credits')!) : 0;
  const projectName = searchParams.get('project') || "Projet Carbone";
  
  const nftId = id || "unknown-id";
  
  useEffect(() => {
    console.log('VerifyNFT component rendered with:', { 
      nftId, 
      credits, 
      projectName,
      isMobile,
      windowWidth: window.innerWidth,
      userAgent: navigator.userAgent,
      path: location.pathname,
      search: location.search
    });
    console.log('Location state:', location.state);
    
    // Simuler un temps de chargement pour assurer une meilleure transition sur mobile
    const timer = setTimeout(() => {
      setIsLoading(false);
      
      // Show confirmation toast when NFT is verified
      if (nftId && nftId !== "unknown-id") {
        setTimeout(() => {
          toastSuccess("Vérification réussie", "Ce certificat NFT a été authentifié avec succès.");
        }, 100);
      }
    }, isMobile ? 500 : 200); // Un délai un peu plus long sur mobile pour assurer la transition
    
    return () => clearTimeout(timer);
  }, [nftId, credits, projectName, location.state, isMobile, location.pathname, location.search]);
  
  const handleGoBack = (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate('/simulator', { replace: true });
    toastInfo("Navigation", "Retour au simulateur");
  };
  
  // Affichage d'un indicateur de chargement pendant la transition
  if (isLoading) {
    return (
      <Layout>
        <div className="container mx-auto px-4 py-16 flex items-center justify-center min-h-[60vh]">
          <div className="text-center">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-brand-600 mb-4"></div>
            <p className="text-gray-600">Vérification du certificat en cours...</p>
          </div>
        </div>
      </Layout>
    );
  }
  
  return (
    <Layout>
      <div className="container mx-auto px-4 py-8 sm:py-16">
        <Button 
          variant="outline" 
          className="mb-6 flex items-center gap-2"
          onClick={handleGoBack}
        >
          <ArrowLeft className="h-4 w-4" />
          <span>Retour au simulateur</span>
        </Button>
        
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-6 sm:mb-10">
            <h1 className="text-2xl sm:text-3xl font-bold text-brand-800 mb-4">Vérification de NFT Carbone</h1>
            <p className="text-gray-600">
              Confirmez l'authenticité et les détails d'un certificat de crédit carbone tokenisé.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-10">
            <div>
              <div className="bg-white p-4 sm:p-6 rounded-xl shadow-md">
                <div className="flex items-center justify-center mb-6">
                  <Badge className="bg-green-100 text-green-800 px-3 py-1 text-sm flex items-center gap-1.5">
                    <Check className="h-4 w-4" />
                    Authentique & Vérifié
                  </Badge>
                </div>
                
                <CarbonNFT 
                  credits={credits} 
                  id={nftId} 
                  projectName={projectName}
                />
              </div>
            </div>
            
            <div className="space-y-4 sm:space-y-6">
              <div className="bg-white p-4 sm:p-6 rounded-xl shadow-md">
                <h2 className="text-xl font-semibold text-brand-700 mb-4 flex items-center gap-2">
                  <Shield className="h-5 w-5 text-brand-500" />
                  Certificat d'Authenticité
                </h2>
                
                <div className="space-y-4">
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">ID du Certificat</h3>
                    <p className="font-mono text-gray-800 break-words">{nftId}</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Émis Par</h3>
                    <p className="text-gray-800">Guteco Certification Authority</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Date d'Émission</h3>
                    <p className="text-gray-800">{new Date().toLocaleDateString()}</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Standard de Vérification</h3>
                    <p className="text-gray-800">ISO 14064 / GHG Protocol</p>
                  </div>
                </div>
              </div>
              
              <div className="bg-white p-4 sm:p-6 rounded-xl shadow-md">
                <h2 className="text-xl font-semibold text-brand-700 mb-4">Détails du Crédit Carbone</h2>
                
                <div className="space-y-4">
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Quantité de Crédits</h3>
                    <p className="text-gray-800">{credits} tonnes de CO2e</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Type de Projet</h3>
                    <p className="text-gray-800">Reforestation / Énergies Renouvelables</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Localisation</h3>
                    <p className="text-gray-800">Afrique Centrale / Amérique Latine</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Impact Social</h3>
                    <p className="text-gray-800">Soutient 15 emplois locaux et 3 communautés</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VerifyNFT;
