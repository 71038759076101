
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import ErrorBoundary from './contexts/ErrorBoundary';

// Fonction simplifiée et optimisée pour le rendu de l'application
const renderApp = () => {
  const rootElement = document.getElementById('root');
  
  if (!rootElement) {
    console.error("Erreur critique: L'élément racine 'root' est introuvable dans le DOM");
    return;
  }
  
  // Logs for debugging
  console.log("Root element exists");
  console.log("Root element contents:", rootElement.innerHTML);
  
  // Création de la racine React une seule fois
  const root = ReactDOM.createRoot(rootElement);
  
  // Rendre l'application avec gestion des erreurs
  root.render(
    <React.StrictMode>
      <ErrorBoundary fallback={
        <div className="flex items-center justify-center h-screen p-4">
          <div className="text-center max-w-md bg-white p-6 rounded-lg shadow-lg">
            <div className="bg-amber-100 rounded-full h-16 w-16 flex items-center justify-center mx-auto mb-4">
              <AlertTriangle className="h-8 w-8 text-amber-600" />
            </div>
            <h1 className="text-2xl font-bold mb-4">GuTeco</h1>
            <p className="mb-6">Une erreur critique est survenue. Veuillez rafraîchir la page.</p>
            <button 
              onClick={() => window.location.reload()} 
              className="px-6 py-2 bg-amber-500 text-black font-medium rounded hover:bg-amber-600"
            >
              Rafraîchir
            </button>
          </div>
        </div>
      }>
        <React.Suspense fallback={
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">
              <div className="rounded-full h-12 w-12 border-4 border-t-brand-600 animate-spin mx-auto"></div>
              <p className="mt-4 text-brand-700">Chargement de GuTeco...</p>
            </div>
          </div>
        }>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.Suspense>
      </ErrorBoundary>
    </React.StrictMode>
  );
  
  console.log("Application rendue avec succès");
};

// Vérification du stockage local simplifiée
const checkStorage = () => {
  try {
    const testKey = '_test_storage_';
    localStorage.setItem(testKey, '1');
    localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    console.warn("Problème d'accès au localStorage:", error);
    
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.innerHTML = `
        <div class="p-8 text-center max-w-md mx-auto mt-12">
          <h1 class="text-2xl font-bold mb-4">GuTeco</h1>
          <p class="mb-4">Problème d'accès au stockage local. Si vous utilisez un mode privé ou avec des restrictions de cookies, certaines fonctionnalités peuvent ne pas fonctionner.</p>
          <button onclick="window.location.reload()" class="px-6 py-2 bg-amber-500 text-black font-medium rounded hover:bg-amber-600">
            Réessayer
          </button>
        </div>
      `;
    }
    return false;
  }
};

// Attendre que le DOM soit prêt avant de rendre l'application
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    if (checkStorage()) {
      renderApp();
    }
  });
} else {
  if (checkStorage()) {
    renderApp();
  }
}

// Gestionnaire d'erreurs global amélioré
window.addEventListener('error', (event) => {
  console.error("Erreur non gérée:", event.error || event.message);
  
  const rootElement = document.getElementById('root');
  if (rootElement && (rootElement.innerHTML.trim() === '' || rootElement.children.length === 0)) {
    rootElement.innerHTML = `
      <div class="p-8 text-center max-w-md mx-auto mt-12">
        <h1 class="text-xl font-bold mb-4">GuTeco</h1>
        <p class="mb-4">Une erreur est survenue. Veuillez rafraîchir la page.</p>
        <button onclick="window.location.reload()" class="px-4 py-2 bg-amber-500 text-black rounded hover:bg-amber-600">
          Rafraîchir
        </button>
      </div>
    `;
  }
});

// Import missing component
import { AlertTriangle } from 'lucide-react';
