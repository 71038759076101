
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CarbonSimulator from '@/components/CarbonSimulator';
import { Button } from '@/components/ui/button';
import { ArrowLeft, Info } from 'lucide-react';
import PricingInfo from '@/components/PricingInfo';
import AIPredictions from '@/components/AIPredictions';
import { Separator } from '@/components/ui/separator';
import { Layout } from '@/components/Layout';
import { AuthProvider, useAuth } from '@/contexts/AuthContext';
import { toastInfo } from '@/hooks/use-toast';
import { useIsMobile, useIsTablet } from '@/hooks/use-mobile';
import FallbackScreen from '@/components/FallbackScreen';

const Simulator = () => {
  const navigate = useNavigate();
  const [showPricingInfo, setShowPricingInfo] = useState(false);
  const auth = useAuth();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [authError, setAuthError] = useState(false);

  // Enhanced logging for debugging
  useEffect(() => {
    console.log('Simulator page mounted with auth:', auth);
    console.log('Device detection:', { 
      isMobile, 
      isTablet, 
      windowWidth: window.innerWidth, 
      windowHeight: window.innerHeight,
      userAgent: navigator.userAgent
    });
    
    // Set auth error state if auth context is not available
    if (!auth && window.location.pathname === '/simulator') {
      console.log('Auth context not available, will render with fallback');
      setAuthError(true);
    }
  }, [auth, isMobile, isTablet]);

  const handleGoBack = (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate('/');
    toastInfo("Navigation", "Retour à la page d'accueil");
  };

  // Constants for carbon conversion
  const avgPricePerTon = 25; // Average price in euros per ton of CO2
  const exampleTonnage = 10000000; // 10 million tons
  const exampleValue = exampleTonnage * avgPricePerTon; // 250 million euros

  const openPricingInfo = (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPricingInfo(true);
    toastInfo("Information", "Détails des prix des crédits carbone");
  };

  // If auth error occurs, use FallbackScreen instead of showing blank screen
  if (authError) {
    return (
      <FallbackScreen 
        message="Impossible de charger le contexte d'authentification. Veuillez réessayer."
        onRetry={() => window.location.reload()}
      />
    );
  }

  // SimulatorContent with proper auth context handling
  const SimulatorContent = () => {
    return (
      <div className="container mx-auto px-4 py-8 pt-24">
        <Button 
          variant="outline" 
          className="mb-6 flex items-center gap-2"
          onClick={handleGoBack}
        >
          <ArrowLeft className="h-4 w-4" />
          <span>Retour à l'accueil</span>
        </Button>
        
        <h1 className="text-2xl font-bold text-brand-800 mb-6">Simulateur de Crédits Carbone</h1>
        <p className="text-gray-600 mb-8">
          Utilisez notre simulateur pour calculer l'impact de vos activités et découvrir comment investir dans des crédits carbone.
        </p>
        
        <div className="bg-brand-50 rounded-lg p-4 sm:p-6 mb-8 border border-brand-100">
          <h2 className="text-xl font-semibold text-brand-800 mb-4 flex items-center gap-2">
            <Info className="h-5 w-5 text-brand-600" />
            Conversion CO₂ en Valeur Financière
          </h2>
          
          <div className="space-y-4">
            <p className="text-gray-700">
              Le prix des crédits carbone varie entre <span className="font-semibold">5€ et 50€</span> par 
              tonne de CO₂ selon le type de projet, avec une moyenne d'environ <span className="font-semibold">25€</span> par tonne.
            </p>
            
            <div className="bg-white rounded-lg p-4 border border-brand-200">
              <h3 className="font-medium text-brand-800 mb-2">Exemple de conversion:</h3>
              <div className="flex flex-col md:flex-row gap-4 items-center">
                <div className="bg-brand-100 rounded-lg px-4 py-3 flex-1 text-center">
                  <p className="text-sm text-brand-600">Quantité de CO₂</p>
                  <p className="text-xl sm:text-2xl font-bold text-brand-800">{exampleTonnage.toLocaleString()} tonnes</p>
                </div>
                <div className="text-xl sm:text-2xl font-bold text-brand-800">≈</div>
                <div className="bg-green-100 rounded-lg px-4 py-3 flex-1 text-center">
                  <p className="text-sm text-green-600">Valeur financière</p>
                  <p className="text-xl sm:text-2xl font-bold text-green-800">{exampleValue.toLocaleString()} €</p>
                </div>
              </div>
              <p className="text-sm text-gray-500 mt-2 text-center">
                Basé sur un prix moyen de {avgPricePerTon}€ par tonne de CO₂
              </p>
            </div>
            
            <div className="flex justify-center">
              <Button 
                variant="outline" 
                className="text-brand-600 border-brand-200 hover:bg-brand-50 flex items-center gap-2 py-2 px-3 sm:py-3 sm:px-4 touch-action-manipulation"
                onClick={openPricingInfo}
              >
                <Info className="h-4 w-4" />
                <span>En savoir plus sur les prix</span>
              </Button>
            </div>
          </div>
        </div>
        
        <CarbonSimulator authContext={auth} />
        
        <Separator className="my-8 sm:my-12" />
        
        <section className="mb-12">
          <AIPredictions />
        </section>
        
        <PricingInfo open={showPricingInfo} onOpenChange={setShowPricingInfo} />
      </div>
    );
  };

  return (
    <Layout>
      <AuthProvider>
        <SimulatorContent />
      </AuthProvider>
    </Layout>
  );
};

export default Simulator;
